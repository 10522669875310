import React, { FC } from "react";

type Props = {
  colors: [string, string] | [string, string, string, string];
  height: number;
  width: number | string;
  x1?: number;
  y1?: number;
  style?: React.CSSProperties;
};

const LinearGradient: FC<Props> = ({
  colors,
  style,
  height,
  width,
  x1 = 0,
  y1 = 0,
}) => {
  // Create a unique id for each gradient instance to prevent conflicts in case multiple gradients are displayed at once
  const gradientId = `gradient${Math.random()}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      style={style}
    >
      <rect x="0" height={height} width={width} fill={`url(#${gradientId})`} />
      <defs>
        {colors.length === 2 ? (
          <linearGradient
            id={gradientId}
            x1={x1}
            y1={y1}
            x2={String(width)}
            y2={String(height)}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colors[0]} />
            <stop offset="1" stopColor={colors[1]} />
          </linearGradient>
        ) : (
          <linearGradient
            id={gradientId}
            x1={x1}
            y1={y1}
            x2={String(width)}
            y2={String(height)}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colors[0]} />
            <stop offset="0.33" stopColor={colors[1]} />
            <stop offset="0.66" stopColor={colors[2]} />
            <stop offset="1" stopColor={colors[3]} />
          </linearGradient>
        )}
      </defs>
    </svg>
  );
};

export default LinearGradient;
