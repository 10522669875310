import useIntersectionObserver from "@hooks/useIntersectionObserver";
import { usePrevious } from "@hooks/usePrevious";
import { useEffect } from "react";

const useScrollIntoView = (
  ref: React.RefObject<HTMLDivElement>,
  condition: boolean,
  block: ScrollLogicalPosition = "start",
): void => {
  const { isInViewport } = useIntersectionObserver({
    target: ref,
    threshold: 0.9,
  });
  const prevIsInViewport = usePrevious(isInViewport);
  useEffect(() => {
    if (condition && !prevIsInViewport && !isInViewport && ref.current) {
      ref.current.scrollIntoView({ block });
    }
  }, [condition, prevIsInViewport, isInViewport, ref, block]);
};

export default useScrollIntoView;
