import { ComponentProps, FC } from "react";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import ShowViewButtonOverlay from "./ShowViewButtonOverlay";
import { deprecatedColors } from "@src/deprecatedDesignSystem/styles/deprecatedColors";

type Props = {
  url: string;
  imageProps?: ComponentProps<"img">;
  showViewButton?: boolean;
};

const PromptMediaImageAndGifFilePreview: FC<Props> = (props) => {
  return (
    <AutoLayout alignSelf="stretch" style={{ position: "relative" }}>
      {props.showViewButton && <ShowViewButtonOverlay url={props.url} />}
      <img
        alt="media"
        src={props.url}
        {...props.imageProps}
        style={{
          overflow: "hidden",
          objectPosition: "center",
          maxHeight: "auto",
          objectFit: "contain",
          width: "100%",
          maxWidth: "100%",
          backgroundColor: deprecatedColors.white,
          ...props.imageProps?.style,
        }}
      />
    </AutoLayout>
  );
};

export default PromptMediaImageAndGifFilePreview;
