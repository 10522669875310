import { ContentTabType } from "@src/components/contentLibrary/hooks/useContentQueryParams";
import { Route } from "nextjs-routes";

export const getPersonDetailRoute = (userId: number): Route => {
  return {
    pathname: "/people/[id]",
    query: {
      id: userId.toString(),
    },
  };
};

export const getRoleDetailRoute = (roleId: number): Route => {
  return {
    pathname: "/roles/[id]",
    query: {
      id: roleId.toString(),
    },
  };
};

export const getLocationDetailRoute = (locationId: number): Route => {
  return {
    pathname: "/locations/[id]",
    query: {
      id: locationId.toString(),
    },
  };
};

export const getLocationGroupDetailRoute = (locationGroupId: string): Route => {
  return {
    pathname: "/location-groups/[id]",
    query: {
      id: locationGroupId,
    },
  };
};

export const getRoleGroupDetailRoute = (roleGroupId: string): Route => {
  return {
    pathname: "/role-groups/[id]",
    query: {
      id: roleGroupId,
    },
  };
};

export const getTagRoute = (tagId: string): Route => {
  return {
    pathname: "/library",
    query: {
      tab: ContentTabType.ALL_CONTENT,
      tagIds: tagId,
    },
  };
};
