import React, { FC, useMemo } from "react";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Text from "@ui/text";
import { deprecatedColors } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import MultiSelectField from "@src/deprecatedDesignSystem/components/MultiSelectField";
import useRoles from "@hooks/useRoles";
import { SINGLE_AND_MULTISELECT_DROPDOWN_HEIGHT } from "./dropdowns/constants";

type Props = {
  label?: string;
  placeholder?: string;
  roleIds: number[];
  setRoleIds: (x: number[]) => void;
  excludedRoleIds?: number[];
};

export const RoleMultiSelectField: FC<Props> = (props) => {
  const { roles } = useRoles();
  const options = useMemo(() => {
    return roles
      .filter((x) => !props.excludedRoleIds?.includes(x.id))
      .map((x) => ({
        value: x.id.toString(),
        label: x.name,
      }));
  }, [props.excludedRoleIds, roles]);
  return (
    <AutoLayout
      direction="vertical"
      spaceBetweenItems={4}
      alignSelf={"stretch"}
      data-testid={"roles-field"}
    >
      <AutoLayout
        direction="horizontal"
        spacingMode="space-between"
        alignSelf="stretch"
      >
        <Text
          type="P3"
          fontWeight="SemiBold"
          color={deprecatedColors.onBackground}
        >
          {props.label || "Roles"}
        </Text>
      </AutoLayout>
      <MultiSelectField
        style={{
          alignSelf: "stretch",
        }}
        values={props.roleIds.map((x) => x.toString())}
        onValueChange={(x) => {
          props.setRoleIds(x.map((x) => Number(x)));
        }}
        options={options}
        fontWeight={"Regular"}
        placeholder={props.placeholder || "Select Roles"}
        dropdownHeight={SINGLE_AND_MULTISELECT_DROPDOWN_HEIGHT}
        searchEnabled={true}
      />
    </AutoLayout>
  );
};

export default RoleMultiSelectField;
