import { ApplyPromptAttemptArgs } from "@src/components/my-training/courseInstance/contexts/CourseInstanceDispatchContext";
import {
  CourseInstanceScreenCourseInstanceFragment,
  LessonInstanceFragment,
  PromptFragment,
  PromptInstanceFragment,
} from "@src/components/my-training/courseInstance/operations.generated";
import { GRADED_PROMPT_TYPES } from "@src/components/my-training/courseInstance/utils/promptTypes";
import { uuid4 } from "@sentry/utils";
import { PromptType } from "@src/types.generated";
import { cloneDeep } from "lodash";
import { DateTime } from "luxon";

export const applyPromptAttemptToCourseInstance = (
  ci: CourseInstanceScreenCourseInstanceFragment,
  payload: ApplyPromptAttemptArgs,
): CourseInstanceScreenCourseInstanceFragment => {
  const now = DateTime.now();
  const newCi = cloneDeep(ci);
  const li = getLessonInstance(newCi, payload.lessonInstanceId);
  const pi = getPromptInstance(newCi, payload.promptInstanceUuid);
  pi.answeredCorrectly = payload.isCorrect;
  pi.attempts.push(payload.promptAttempt);
  const shouldPromptBeComplete =
    payload.isCorrect ||
    payload.skipCorrect ||
    reachedMaxIncorrectAttempts(newCi.course.enablePromptRetries, pi);
  pi.completedAt = shouldPromptBeComplete ? now.toString() : undefined;
  maybeMarkLessonInstanceComplete(li, now);
  maybeMarkCourseInstanceComplete(newCi, now);
  maybeStartNextPromptInstance(li, now);
  setLessonInstancePercentComplete(li);
  setCourseInstancePercentComplete(newCi);
  newCi.startedAt = newCi.startedAt || now.toString();
  if (isGradedPrompt(pi.prompt)) {
    setCourseInstanceAccuracyPercent(newCi);
  }
  return newCi;
};

export const applyStartNextLessonInstance = (
  ci: CourseInstanceScreenCourseInstanceFragment,
): CourseInstanceScreenCourseInstanceFragment => {
  const now = DateTime.now();
  const li = getFirstIncompleteLessonInstance(ci)!;
  maybeStartLessonInstance(li, now);
  return ci;
};

export const activePromptAndLessonInstance = (
  courseInstance: CourseInstanceScreenCourseInstanceFragment,
): {
  activePromptInstance: PromptInstanceFragment | undefined;
  activeLessonInstance: LessonInstanceFragment | undefined;
} => {
  const activeLessonInstance = courseInstance.lessonInstances.find(
    (li) => li.startedAt && !li.completedAt,
  );
  const activePromptInstance = activeLessonInstance?.promptInstances.find(
    (pi) => !pi.completedAt,
  );
  return {
    activeLessonInstance,
    activePromptInstance,
  };
};

export const getLessonInstance = (
  courseInstance: CourseInstanceScreenCourseInstanceFragment,
  lessonInstanceId: string,
): LessonInstanceFragment => {
  return courseInstance.lessonInstances.find(
    (li) => li.id === lessonInstanceId,
  )!;
};

export const getPromptInstance = (
  courseInstance: CourseInstanceScreenCourseInstanceFragment,
  promptInstanceUuid: string,
): PromptInstanceFragment => {
  const promptInstances = getPromptInstances(courseInstance);
  return promptInstances.find((pi) => pi.uuid === promptInstanceUuid)!;
};

export const getFirstIncompleteLessonInstance = (
  courseInstance: CourseInstanceScreenCourseInstanceFragment,
): LessonInstanceFragment | undefined => {
  return courseInstance.lessonInstances.find((li) => !li.completedAt);
};

export const maybeMarkLessonInstanceComplete = (
  lessonInstance: LessonInstanceFragment,
  now: DateTime,
): void => {
  if (!lessonInstance.promptInstances.find((pi) => !pi.completedAt)) {
    lessonInstance.completedAt = now.toString();
  }
};

export const maybeMarkCourseInstanceComplete = (
  courseInstance: CourseInstanceScreenCourseInstanceFragment,
  now: DateTime,
): void => {
  if (!courseInstance.lessonInstances.find((li) => !li.completedAt)) {
    courseInstance.completedAt = now.toString();
  }
};

export const maybeStartNextPromptInstance = (
  lessonInstance: LessonInstanceFragment,
  now: DateTime,
): void => {
  const firstIncompletePromptInstance = lessonInstance.promptInstances.find(
    (pi) => !pi.completedAt,
  );
  if (
    firstIncompletePromptInstance &&
    !firstIncompletePromptInstance.startedAt
  ) {
    firstIncompletePromptInstance.startedAt = now.toString();
  }
};

export const maybeInitializePromptInstances = (
  lessonInstance: LessonInstanceFragment,
): void => {
  lessonInstance.promptInstances = lessonInstance.lesson.prompts.map((p) => {
    const existingPromptInstance = lessonInstance.promptInstances.find(
      (pi) => pi.prompt.id === p.id,
    );
    if (existingPromptInstance) {
      return existingPromptInstance;
    } else {
      return initializePromptInstance(p);
    }
  });
};

export const initializePromptInstance = (
  prompt: PromptFragment,
): PromptInstanceFragment => {
  return {
    uuid: uuid4(),
    prompt,
    numFailures: 0,
    attempts: [],
    startedAt: undefined,
  };
};

export const maybeStartLessonInstance = (
  lessonInstance: LessonInstanceFragment,
  now: DateTime,
): void => {
  if (!lessonInstance?.startedAt) {
    lessonInstance.startedAt = now.toString();
  }
  maybeInitializePromptInstances(lessonInstance);
  maybeStartNextPromptInstance(lessonInstance, now);
};

export const initializeCourseInstance = (
  ci: CourseInstanceScreenCourseInstanceFragment,
): CourseInstanceScreenCourseInstanceFragment => {
  const newCi = cloneDeep(ci);
  newCi.startedAt = newCi.startedAt || DateTime.now().toString();
  const firstLi = newCi.lessonInstances[0];
  newCi.lessonInstances.forEach((li) => {
    maybeInitializePromptInstances(li);
  });
  maybeStartLessonInstance(firstLi, DateTime.now());
  newCi.lessonInstances.forEach((li) => {
    setLessonInstancePercentComplete(li);
  });
  setCourseInstanceAccuracyPercent(newCi);
  return newCi;
};

export const calculateLessonInstancePercentComplete = (
  li: LessonInstanceFragment,
): number => {
  const totalPrompts = li.lesson.prompts.length;
  if (!li.startedAt || totalPrompts === 0) {
    return 0;
  } else if (li.completedAt) {
    return 100;
  }
  const numCompletedPromptInstances = li.promptInstances.filter(
    (pi) => pi.completedAt,
  ).length;
  return (numCompletedPromptInstances / totalPrompts) * 100;
};

export const calculateCourseInstancePercentComplete = (
  ci: CourseInstanceScreenCourseInstanceFragment,
): number => {
  const allPrompts = getPrompts(ci);
  const allPromptInstances = getPromptInstances(ci);
  if (!ci.startedAt || allPrompts.length === 0) {
    return 0;
  } else if (ci.completedAt) {
    return 100;
  }
  const numCompletedPromptInstances = allPromptInstances.filter(
    (pi) => pi.completedAt,
  ).length;
  return (numCompletedPromptInstances / allPrompts.length) * 100;
};

export const setLessonInstancePercentComplete = (
  li: LessonInstanceFragment,
): void => {
  li.percentComplete = calculateLessonInstancePercentComplete(li);
};

export const setCourseInstancePercentComplete = (
  ci: CourseInstanceScreenCourseInstanceFragment,
): void => {
  ci.percentComplete = calculateCourseInstancePercentComplete(ci);
};

export const reachedMaxIncorrectAttempts = (
  enableRetries: boolean,
  promptInstance: PromptInstanceFragment,
): boolean => {
  if (!enableRetries) {
    return true;
  }
  const { prompt, attempts } = promptInstance;
  return attempts.length >= maxIncorrectAttempts(prompt);
};

export const maxIncorrectAttempts = (prompt: PromptFragment): number => {
  if (prompt.type === PromptType.GradedFreeResponse) {
    return 2;
  } else if (
    prompt.type === PromptType.MultipleChoice ||
    prompt.type === PromptType.ImageChoice
  ) {
    return responseOptionNumAcceptedAttempts(prompt);
  } else if (prompt.type === PromptType.AudioResponse) {
    return 2;
  } else {
    return 1;
  }
};

export const filterGradedPromptInstances = (
  pis: PromptInstanceFragment[],
): PromptInstanceFragment[] => {
  const gradedPromptTypes = new Set(GRADED_PROMPT_TYPES);
  return pis.filter((pi) => gradedPromptTypes.has(pi.prompt.type));
};

export const getPromptInstances = (
  ci: CourseInstanceScreenCourseInstanceFragment,
): PromptInstanceFragment[] => {
  const reducer = (
    accumulator: PromptInstanceFragment[],
    li: LessonInstanceFragment,
  ) => {
    return [...accumulator, ...li.promptInstances];
  };
  return ci.lessonInstances.reduce(reducer, []);
};

export const getPrompts = (
  ci: CourseInstanceScreenCourseInstanceFragment,
): PromptFragment[] => {
  const reducer = (
    accumulator: PromptFragment[],
    li: LessonInstanceFragment,
  ) => {
    return [...accumulator, ...li.lesson.prompts];
  };
  return ci.lessonInstances.reduce(reducer, []);
};

export const filterCompletedPromptInstances = (
  pis: PromptInstanceFragment[],
): PromptInstanceFragment[] => {
  return pis.filter((pi) => pi.completedAt);
};

export const calculateCourseInstanceAccuracyPercent = (
  ci: CourseInstanceScreenCourseInstanceFragment,
): number | undefined => {
  const completedPromptInstances: PromptInstanceFragment[] =
    filterCompletedPromptInstances(getPromptInstances(ci));
  const gradedPromptInstances = filterGradedPromptInstances(
    completedPromptInstances,
  );
  const correctPromptInstances = gradedPromptInstances.filter(
    (pi) => pi.answeredCorrectly,
  );
  const gradedPromptInstanceCount = gradedPromptInstances.length;
  const correctPromptInstanceCount = correctPromptInstances.length;
  if (gradedPromptInstanceCount === 0) {
    return undefined;
  } else {
    return (correctPromptInstanceCount / gradedPromptInstanceCount) * 100;
  }
};

export const setCourseInstanceAccuracyPercent = (
  ci: CourseInstanceScreenCourseInstanceFragment,
): void => {
  ci.accuracy = calculateCourseInstanceAccuracyPercent(ci);
};

export const isGradedPrompt = (p: PromptFragment): boolean => {
  const gradedPromptTypes = new Set(GRADED_PROMPT_TYPES);
  return gradedPromptTypes.has(p.type);
};

export const responseOptionNumAcceptedAttempts = (
  prompt: PromptFragment,
): number => {
  return prompt.responseOptions.length === 2 ? 1 : 2;
};

export const isGiphyUrl = (url: string): boolean => {
  return url.includes("giphy");
};
