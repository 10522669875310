import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Modal from "@src/deprecatedDesignSystem/components/Modal";
import { StyleSheet } from "aphrodite";
import TitleHeader from "@src/deprecatedDesignSystem/components/TitleHeader";
import { useModal } from "@src/hooks/useModal";
import NewContentCard from "@src/components/courseCatalog/NewContentCard";
import BlankCourseImage from "./BlankCourseImage";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import CourseTemplateImage from "./CourseTemplateImage";
import AiAssistImage from "./AiAssistImage";
import { FC } from "react";
import {
  CreatedCourseFragment,
  useCreateCourseMutation,
} from "./CategoryNav.generated";
import { useTracking } from "@src/hooks/useTracking";
import { useToast } from "@src/hooks/useToast";
import AIGenerateCourseModal from "../modals/ai/AIGenerateCourseModal";
import AiAssistBadge from "./AiAssistBadge";

type Props = {
  onSelectMarketplaceOption: () => void;
  onCourseCreated: (course: CreatedCourseFragment) => void;
};

const NewCourseModal: FC<Props> = (props) => {
  const { showModal, closeModal } = useModal();
  const { trackEvent } = useTracking();
  const { addErrorToast } = useToast();
  const [createCourseMutation, { loading: createCourseLoading }] =
    useCreateCourseMutation({
      variables: {
        name: "",
      },
      onCompleted: (data) => {
        if (data.createCourse.success && data.createCourse.course) {
          props.onCourseCreated(data.createCourse.course);
          closeModal();
          trackEvent("created_blank_course", {
            id: data.createCourse?.course?.id,
          });
        } else {
          addErrorToast({
            ...data.createCourse,
            callsite: "add_blank_course",
          });
        }
      },
    });

  return (
    <Modal
      header={
        <TitleHeader
          title="Create course"
          onCancelClick={closeModal}
          styleDeclaration={styles.header}
        />
      }
      styleDeclaration={styles.modal}
      scrollContent={false}
    >
      <AutoLayout
        direction="vertical"
        flex={1}
        width="100%"
        styleDeclaration={styles.content}
      >
        <AutoLayout flex={1} alignSelf="stretch" styleDeclaration={styles.row}>
          <NewContentCard
            title="Blank Course"
            subtitle="Create a new course from scratch."
            color={deprecatedTones.blue9}
            image={<BlankCourseImage />}
            onClick={() => {
              if (!createCourseLoading) {
                createCourseMutation();
                closeModal();
              }
            }}
            data-testid="blank-course-button"
          />
          <NewContentCard
            title="AI-generated course"
            subtitle="Use AI to create your first draft."
            color={deprecatedTones.purple8}
            image={<AiAssistImage />}
            onClick={() => {
              showModal(
                <AIGenerateCourseModal
                  closeModal={() => closeModal({ closeAll: true })}
                  onCourseCreated={props.onCourseCreated}
                />,
              );
            }}
            topLeftBadge={
              <AiAssistBadge styleDeclaration={styles.aiAssistBadge} />
            }
          />
        </AutoLayout>
        <AutoLayout flex={1} alignSelf="stretch" styleDeclaration={styles.row}>
          <NewContentCard
            title="Prebuilt Course from Marketplace"
            subtitle="Build off of an existing template."
            color={deprecatedTones.green10}
            image={<CourseTemplateImage />}
            onClick={() => {
              closeModal();
              props.onSelectMarketplaceOption();
            }}
          />
        </AutoLayout>
      </AutoLayout>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modal: {
    borderRadius: 32,
    backgroundColor: deprecatedTones.white,
    overflow: "hidden",
    "ms-overflow-style": "-ms-autohiding-scrollbar",
    "scrollbar-width": "none",
    "::-webkit-scrollbar": {
      display: "none",
      width: 0,
      height: 0,
    },
    scrollbarWidth: "none",
  },
  header: {
    paddingRight: 16,
    paddingLeft: 16,
  },
  content: {
    gap: 16,
    padding: 24,
  },
  row: {
    gap: 16,
  },
  card: {
    padding: 16,
    borderRadius: 20,
    backgroundColor: deprecatedTones.white,
    boxShadow:
      "0px 4px 28px rgba(0, 0, 0, 0.03), 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.02)",
  },
  title: {
    marginBottom: 4,
  },
  aiAssistBadge: {
    height: 64,
    paddingLeft: 16,
  },
});

export default NewCourseModal;
