import Spacer from "@components/ui/Spacer";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Text from "@ui/text";
import { StyleSheet } from "aphrodite";
import { FC } from "react";

export enum ContentType {
  COURSE = "Course",
  MODULE = "Module",
  FILE = "Resource",
  SKILL = "Skill",
}

type Props = {
  type: ContentType;
};

export const contentTypeCopy: { [key in ContentType]: string } = {
  [ContentType.COURSE]:
    "The main building block of Opus. Use media and questions to explain, validate, and reinforce topics.",
  [ContentType.MODULE]:
    "Collection of Courses, Resources, and Check-ins. Perfect for New Hire and Role Certification.",
  [ContentType.FILE]:
    "Uploaded resources easily accessible by employees. Embed in Courses and Modules.",
  [ContentType.SKILL]:
    "Track hands-on training moments. Understand employee development.",
};

export const contentTypeFileName: { [key in ContentType]: string } = {
  [ContentType.COURSE]: "course-with-gradient.png",
  [ContentType.MODULE]: "path-with-gradient.png",
  [ContentType.FILE]: "file-with-gradient.png",
  [ContentType.SKILL]: "skill-with-gradient.png",
};

const ContentExplainerCard: FC<Props> = ({ type }) => {
  return (
    <AutoLayout
      direction="vertical"
      flex={1}
      alignSelf="stretch"
      padding={12}
      width={200}
      styleDeclaration={styles.container}
    >
      <div
        style={{
          backgroundImage: `url(/images/${contentTypeFileName[type]})`,
          backgroundSize: "cover",
          height: 107,
          width: "100%",
          borderRadius: 12,
        }}
      />
      <Spacer size={8} />
      <AutoLayout
        direction="vertical"
        flex={1}
        alignSelf="stretch"
        paddingLeft={4}
        spaceBetweenItems={2}
      >
        <Text type="P3" fontWeight="SemiBold" color={deprecatedTones.gray13}>
          {type}
        </Text>
        <Text
          type="P3"
          fontWeight="SemiBold"
          color={deprecatedTones.gray8}
          multiline
        >
          {contentTypeCopy[type]}
        </Text>
      </AutoLayout>
    </AutoLayout>
  );
};

export default ContentExplainerCard;

const styles = StyleSheet.create({
  container: {
    background: deprecatedTones.white,
    boxShadow: "0px 0px 50px rgba(154, 146, 162, 0.21)",
    /* Note: backdrop-filter has minimal browser support */
    backdropFilter: "blur(30px)",
    borderRadius: 16,
  },
  tabsContainer: {
    display: "flex",
    alignItems: "center",
    gap: 24,
  },
});
