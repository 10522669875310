import React, { FC } from "react";
import PromptMediaFileAndLinkContainer from "@src/components/libraryItemDetailPages/course/PromptMediaFileAndLinkContainer";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import Text from "@ui/text";
import Spacer from "@components/ui/Spacer";
import useBrandColor from "@hooks/useBrandColor";
import { addDeprecatedOpacityToHexColor } from "@src/deprecatedDesignSystem/styles/deprecatedColors";

type Props = {
  url: string;
  title?: string;
};

const PromptMediaAudioFilePreview: FC<Props> = (props) => {
  const brandColor = useBrandColor();
  return (
    <PromptMediaFileAndLinkContainer
      centerContent={
        <AutoLayout
          direction={"vertical"}
          padding={22}
          alignmentHorizontal={"center"}
        >
          <DeprecatedIcon
            type={"speaker-volume-high"}
            iconSize={48}
            containerSize={48}
            color={brandColor}
          />
          {props.title && (
            <>
              <Spacer size={14} />
              <Text type={"H3"} fontWeight={"SemiBold"} color={brandColor}>
                {props.title}
              </Text>
            </>
          )}
        </AutoLayout>
      }
      footerContent={
        <AutoLayout alignmentHorizontal={"center"} alignSelf={"stretch"}>
          <audio
            src={props.url}
            style={{
              borderRadius: 99,
              filter: `drop-shadow(0px 0px 3.58904px ${addDeprecatedOpacityToHexColor(
                brandColor,
                0.5,
              )}) drop-shadow(0px 7.17809px 54.36px ${addDeprecatedOpacityToHexColor(
                brandColor,
                0.5,
              )})`,
            }}
            controls
          />
        </AutoLayout>
      }
    />
  );
};

export default PromptMediaAudioFilePreview;
