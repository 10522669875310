import { KeyboardEventHandler, useCallback, useState } from "react";

type Return = {
  keyboardSelectIndex: number;
  setKeyboardSelectIndex: (index: number) => void;
  onKeyDown: KeyboardEventHandler;
};

const useKeyboardNavigation = (
  numItems: number,
  selectItemAtIndex: (index: number) => void,
  onEscape?: () => void,
  onBackspace?: () => void,
): Return => {
  const [keyboardSelectIndex, setKeyboardSelectIndex] = useState(0);
  const onKeyDown = useCallback(
    (evt: React.KeyboardEvent) => {
      if (evt.key === "ArrowDown") {
        let newIndex = keyboardSelectIndex + 1;
        if (newIndex >= numItems) {
          newIndex = 0;
        }
        setKeyboardSelectIndex(newIndex);
      }
      if (evt.key === "Escape" || (evt.key === "k" && evt.metaKey)) {
        onEscape && onEscape();
      }
      if (evt.key === "ArrowUp") {
        let newIndex = keyboardSelectIndex - 1;
        if (newIndex < 0) {
          newIndex = numItems - 1;
        }
        setKeyboardSelectIndex(newIndex);
      }
      if (
        evt.key === "Enter" &&
        keyboardSelectIndex !== null &&
        keyboardSelectIndex >= 0 &&
        keyboardSelectIndex < numItems
      ) {
        selectItemAtIndex(keyboardSelectIndex);
      }
      if (evt.key === "Backspace") {
        onBackspace && onBackspace();
      }
    },
    [keyboardSelectIndex, numItems, onBackspace, onEscape, selectItemAtIndex],
  );
  return {
    keyboardSelectIndex,
    setKeyboardSelectIndex,
    onKeyDown,
  };
};

export default useKeyboardNavigation;
