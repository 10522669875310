import { ApolloError } from "@apollo/client";
import {
  OrgSharpConfigFragment,
  useOrgSharpConfigQuery,
} from "@contexts/OrgSharpConfigContext/OrgSharpConfigContext.generated";
import gql from "graphql-tag";
import React, { FC, useMemo } from "react";

export interface OrgSharpConfigContextState {
  orgSharpConfig: OrgSharpConfigFragment | null;
  loading: boolean;
  error: ApolloError | undefined;
}

export const OrgSharpConfigContext = React.createContext<
  OrgSharpConfigContextState | undefined
>(undefined);

type Props = {
  children: React.ReactNode;
};

const OrgSharpConfigContextProvider: FC<Props> = ({ children }) => {
  const { data, loading, error } = useOrgSharpConfigQuery();
  const state = useMemo(() => {
    const orgSharpConfig = data?.AdminOrgSharpConfig || null;
    return {
      orgSharpConfig,
      loading,
      error,
    };
  }, [data?.AdminOrgSharpConfig, loading, error]);
  return (
    <OrgSharpConfigContext.Provider value={state}>
      {children}
    </OrgSharpConfigContext.Provider>
  );
};

export default OrgSharpConfigContextProvider;

gql`
  fragment OrgSharpConfig on OrgSharpConfig {
    id
    org {
      ...MeOrg
    }
    dueDateDays
    sharpPolicy {
      ...Media
    }
    realWorldComplaintFormLocation {
      ...TranslationSet
    }
    hrEmail
    hotlinePhoneNumber
    lastEditedAt
    lastEditedBy {
      id
      name
    }
    createdAt
    createdBy {
      id
      name
    }
  }

  query OrgSharpConfig {
    AdminOrgSharpConfig {
      ...OrgSharpConfig
    }
  }
`;
