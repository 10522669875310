import PromptMediaPreview from "@src/components/libraryItemDetailPages/course/PromptMediaPreview";
import EmojiPreview from "@components/ui/EmojiPreview";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import { FC, useMemo } from "react";
import { isGiphyUrl } from "./utils/utils";

type Props = {
  id: string;
  mediaUrl?: string;
  emoji?: string;
  showViewButton?: boolean;
  isGifConvertedToMp4?: boolean;
};

const MediaPreview: FC<Props> = (props) => {
  return useMemo(() => {
    if (props.mediaUrl) {
      return (
        <PromptMediaPreview
          promptUuid={props.id}
          url={props.mediaUrl}
          isGifConvertedToMp4={props.isGifConvertedToMp4}
          title={""}
          videoPlayerProps={{
            style: {
              width: "100%",
            },
          }}
          unfurledMediaProps={{
            ogVideoIframeProps: {
              style: {
                minWidth: "100%",
                height: 200,
              },
            },
          }}
          showViewButton={props.showViewButton ?? !isGiphyUrl(props.mediaUrl)}
        />
      );
    } else if (props.emoji) {
      return (
        <AutoLayout
          direction={"vertical"}
          alignSelf={"stretch"}
          alignmentHorizontal={"center"}
          marginHorizontal={6}
          marginTop={6}
          style={{
            position: "relative",
            borderRadius: 26,
            overflow: "hidden",
            flexShrink: 0,
          }}
        >
          <EmojiPreview emoji={props.emoji} />
        </AutoLayout>
      );
    } else {
      return null;
    }
  }, [props]);
};

export default MediaPreview;
