import { CSSProperties, FC, useMemo } from "react";
import { css, StyleDeclaration, StyleSheet } from "aphrodite";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import { useKeystrokes } from "@src/hooks/useKeystrokes";

type Props = {
  value: string;
  onChange: (value: string) => void;
  autoFocus?: boolean;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  inputClassName?: string;
  style?: CSSProperties;
  styleDeclaration?: StyleDeclaration;
};

const SearchBar: FC<Props> = ({
  value,
  onChange,
  onKeyDown,
  placeholder = "Search...",
  style,
  styleDeclaration,
  autoFocus,
}) => {
  const keystrokeConfig = useMemo(() => {
    return {
      Escape: (event: { preventDefault: () => void }) => {
        event.preventDefault();
        onChange("");
      },
    };
  }, [onChange]);
  useKeystrokes(keystrokeConfig);

  return (
    <AutoLayout
      direction="horizontal"
      alignmentVertical="center"
      style={style}
      styleDeclaration={[styles.container, styleDeclaration]}
    >
      <DeprecatedIcon type="search" color={deprecatedTones.gray6} />
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onInput={(e) => onChange((e.target as HTMLInputElement).value)}
        onKeyDown={(e) => (onKeyDown ? onKeyDown(e) : {})}
        autoFocus={autoFocus}
        className={css(styles.input)}
      />
      {value && (
        <AutoLayout
          alignmentHorizontal="center"
          alignmentVertical="center"
          styleDeclaration={styles.clear}
          onClick={() => onChange("")}
        >
          <DeprecatedIcon
            type="close"
            containerSize={14}
            iconSize={14}
            strokeWidth={3}
            color={deprecatedTones.white}
          />
        </AutoLayout>
      )}
    </AutoLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingRight: 12,
    paddingLeft: 12,
    borderRadius: 50,
    backgroundColor: deprecatedTones.gray4Alpha,
  },
  input: {
    flex: 1,
    height: 40,
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: 14,
    textOverflow: "ellipsis",
    color: deprecatedTones.black,
    outline: "none",
    border: "none",
    borderRadius: 8,
    backgroundColor: "transparent",
    "::placeholder": {
      color: deprecatedTones.gray5,
    },
  },
  clear: {
    width: 18,
    height: 18,
    backgroundColor: deprecatedTones.gray5,
    borderRadius: 24,
    cursor: "pointer",
  },
});

export default SearchBar;
