import React, { FC } from "react";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import ClickableIframe from "@components/ui/ClickableIframe";
import Text from "@ui/text";
import PromptMediaFileAndLinkContainer from "@src/components/libraryItemDetailPages/course/PromptMediaFileAndLinkContainer";
import Spacer from "@components/ui/Spacer";
import useBrandColor from "@hooks/useBrandColor";
import ShowViewButtonOverlay from "./ShowViewButtonOverlay";
import { addDeprecatedOpacityToHexColor } from "@src/deprecatedDesignSystem/styles/deprecatedColors";

const PDF_WIDTH = 180;

type Props = {
  mediaUrl: string;
  title?: string;
  clickableIframeProps?: Partial<React.ComponentProps<typeof ClickableIframe>>;
  showViewButton?: boolean;
};

const PromptMediaPdfPreview: FC<Props> = (props) => {
  const brandColor = useBrandColor();

  return (
    <PromptMediaFileAndLinkContainer
      overlayContent={
        props.showViewButton ? (
          <ShowViewButtonOverlay url={props.mediaUrl} buttonPosition="center" />
        ) : null
      }
      centerContent={
        <AutoLayout direction={"vertical"} alignmentHorizontal={"center"}>
          <AutoLayout
            alignmentVertical={"center"}
            alignmentHorizontal={"center"}
            style={{
              aspectRatio: "128 / 180",
              width: PDF_WIDTH,
              overflow: "hidden",
              borderRadius: 16,
              filter: `drop-shadow(0px 0px 3.58904px ${addDeprecatedOpacityToHexColor(
                brandColor,
                0.5,
              )}) drop-shadow(0px 7.17809px 54.36px ${addDeprecatedOpacityToHexColor(
                brandColor,
                0.5,
              )})`,
            }}
          >
            <ClickableIframe
              src={props.mediaUrl}
              title="PDF"
              onClick={() => {
                window.open(props.mediaUrl, "_blank");
              }}
              {...props.clickableIframeProps}
              style={{
                aspectRatio: "128 / 180",
                width: PDF_WIDTH + 4,
                outline: "none",
                border: "none",
              }}
            />
          </AutoLayout>
          <Spacer size={24} />
          <Text type={"H3"} fontWeight={"SemiBold"} color={brandColor}>
            {props.title || "PDF"}
          </Text>
        </AutoLayout>
      }
    />
  );
};

export default PromptMediaPdfPreview;
