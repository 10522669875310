import {
  OrgSharpConfigContext,
  OrgSharpConfigContextState,
} from "@contexts/OrgSharpConfigContext/OrgSharpConfigContext";
import { useContext } from "react";

const useOrgSharpConfigContext = (): OrgSharpConfigContextState => {
  const contextVal = useContext(OrgSharpConfigContext);
  if (!contextVal) {
    throw Error("forgot to initialize OrgSharpConfigContext");
  }
  return contextVal;
};

export default useOrgSharpConfigContext;
