import OpenGraphLinkPreview, {
  OgData,
} from "@src/components/libraryItemDetailPages/course/OpenGraphLinkPreview";
import OpenGraphVideoPlayerIframe from "@src/components/libraryItemDetailPages/course/OpenGraphVideoPlayerIframe";
import PromptMediaAudioFilePreview from "@src/components/libraryItemDetailPages/course/PromptMediaAudioFilePreview";
import PromptMediaPdfPreview from "@src/components/libraryItemDetailPages/course/PromptMediaPdfPreview";
import { VideoRefMapContext } from "@src/components/libraryItemDetailPages/course/contexts/VideoRefMapContext";
import useMediaMetadata from "@src/components/libraryItemDetailPages/course/hooks/useMediaMetadata";
import useTranslationModeContext from "@src/components/libraryItemDetailPages/course/hooks/useTranslationModeContext";
import ClickableIframe from "@components/ui/ClickableIframe";
import { useOpenGraphDataQuery } from "@components/ui/MediaPreview.generated";
import VideoPlayer from "@src/deprecatedDesignSystem/components/VideoPlayer";
import { LinkType } from "@utils/files";
import { isVimeoRedirectLink } from "@utils/media";
import React, { FC, useContext, useEffect, useMemo, useRef } from "react";
import PromptMediaImageAndGifFilePreview from "./PromptMediaImageAndGifFilePreview";

type Props = {
  promptUuid: string;
  url: string;
  title?: string;
  imageProps?: React.ComponentProps<"img">;
  clickableIframeProps?: Partial<React.ComponentProps<typeof ClickableIframe>>;
  videoPlayerProps?: Partial<React.ComponentProps<typeof VideoPlayer>>;
  unfurledMediaProps?: Partial<
    React.ComponentProps<typeof OpenGraphLinkPreview>
  >;
  showViewButton?: boolean;
  isGifConvertedToMp4?: boolean;
};

const PromptMediaPreview: FC<Props> = (props) => {
  const { linkType, isVideoOrMuxVideo } = useMediaMetadata(props.url);
  const openGraphQuery = useOpenGraphDataQuery({
    variables: {
      url: props.url,
    },
  });
  const translationModeContext = useTranslationModeContext();
  const selectedLanguage = useMemo(() => {
    if (translationModeContext.translationModeEnabled) {
      return translationModeContext.translationLanguage;
    }
    return "en";
  }, [
    translationModeContext.translationLanguage,
    translationModeContext.translationModeEnabled,
  ]);
  const videoRef = useRef<HTMLVideoElement>(null);
  const { addRef: addVideoRef } = useContext(VideoRefMapContext);
  useEffect(() => {
    if (isVideoOrMuxVideo) {
      addVideoRef(props.promptUuid, videoRef);
    }
  }, [addVideoRef, isVideoOrMuxVideo, props.promptUuid]);
  const ogData = openGraphQuery.data?.OpenGraphData as OgData | undefined;
  if (!linkType) return null;
  if (linkType === LinkType.AUDIO) {
    return <PromptMediaAudioFilePreview url={props.url} title={props.title} />;
  } else if ([LinkType.IMAGE, LinkType.GIF].includes(linkType)) {
    return (
      <PromptMediaImageAndGifFilePreview
        url={props.url}
        showViewButton={props.showViewButton}
        {...props.imageProps}
      />
    );
  } else if (props.isGifConvertedToMp4) {
    return (
      <VideoPlayer
        _ref={videoRef}
        controls={false}
        autoPlay={true}
        loop={true}
        muted={true}
        playsInline={true}
        src={props.url}
        language={selectedLanguage}
        {...props.videoPlayerProps}
      />
    );
  } else if (isVideoOrMuxVideo || isVimeoRedirectLink(props.url)) {
    return (
      <VideoPlayer
        _ref={videoRef}
        controls
        src={props.url}
        language={selectedLanguage}
        {...props.videoPlayerProps}
      />
    );
  } else if (linkType === LinkType.PDF) {
    return (
      <PromptMediaPdfPreview
        mediaUrl={props.url}
        title={props.title}
        showViewButton={props.showViewButton}
        clickableIframeProps={props.clickableIframeProps}
      />
    );
  } else if (linkType === LinkType.HOSTED_VIDEO) {
    return <OpenGraphVideoPlayerIframe ogData={ogData} mediaUrl={props.url} />;
  } else {
    return (
      <OpenGraphLinkPreview
        loading={openGraphQuery.loading}
        ogData={ogData}
        url={props.url}
        {...props.unfurledMediaProps}
      />
    );
  }
};

export default PromptMediaPreview;
