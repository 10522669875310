import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateCourseMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
}>;


export type CreateCourseMutation = { __typename?: 'Mutation', createCourse: { __typename?: 'CourseMutationResult', success: boolean, course?: { __typename?: 'Course', id: number, draftVersion: { __typename?: 'CourseVersion', id: number }, libraryItem: { __typename?: 'LibraryItem', id: string } } | null, error?: { __typename?: 'Error', code: string, message?: string | null } | null } };

export type CreatedCourseFragment = { __typename?: 'Course', id: number, draftVersion: { __typename?: 'CourseVersion', id: number }, libraryItem: { __typename?: 'LibraryItem', id: string } };

export const CreatedCourseFragmentDoc = gql`
    fragment CreatedCourse on Course {
  id
  draftVersion {
    id
  }
  libraryItem {
    id
  }
}
    `;
export const CreateCourseDocument = gql`
    mutation CreateCourse($name: String!) {
  createCourse(name: $name) {
    course {
      ...CreatedCourse
    }
    success
    error {
      code
      message
    }
  }
}
    ${CreatedCourseFragmentDoc}`;
export type CreateCourseMutationFn = Apollo.MutationFunction<CreateCourseMutation, CreateCourseMutationVariables>;

/**
 * __useCreateCourseMutation__
 *
 * To run a mutation, you first call `useCreateCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseMutation, { data, loading, error }] = useCreateCourseMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateCourseMutation(baseOptions?: Apollo.MutationHookOptions<CreateCourseMutation, CreateCourseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCourseMutation, CreateCourseMutationVariables>(CreateCourseDocument, options);
      }
export type CreateCourseMutationHookResult = ReturnType<typeof useCreateCourseMutation>;
export type CreateCourseMutationResult = Apollo.MutationResult<CreateCourseMutation>;
export type CreateCourseMutationOptions = Apollo.BaseMutationOptions<CreateCourseMutation, CreateCourseMutationVariables>;