import React from "react";
import { useOpenGraphDataQuery } from "@src/components/ui/MediaPreview.generated";
import { getLinkType, LinkType } from "@src/utils/files";
import FileTextOutlineIcon from "@src/ui/icons/18px/file-text-outline";
import { Skeleton } from "@src/ui/skeleton";
import { cn } from "@src/ui/lib/utils";

type Props = {
  url: string;
  className?: string;
};

const PdfPreview: React.FC<Props> = (props) => {
  const linkType = getLinkType(props.url);
  const { data, loading } = useOpenGraphDataQuery({
    variables: { url: props.url },
    skip: linkType !== LinkType.PDF,
  });
  const onClick = React.useCallback(() => {
    window.open(props.url, "_blank");
  }, [props.url]);
  if (loading) return <Skeleton className="size-full" />;
  const alt = data?.OpenGraphData["og:title"] || "PDF preview";
  const ogImage = data?.OpenGraphData["og:image"];
  return (
    <div
      className={cn(
        "flex size-full cursor-pointer flex-col rounded-md bg-muted",
        props.className,
      )}
      onClick={onClick}
    >
      {ogImage ? (
        <img src={ogImage} alt={alt} className="size-full object-cover" />
      ) : (
        <div className="flex size-full items-center justify-center">
          <FileTextOutlineIcon />
        </div>
      )}
    </div>
  );
};

export default PdfPreview;
