import React from "react";
import { MediaFragment, useFileUpload } from "@src/hooks/useFileUpload";
import { useDropzone } from "react-dropzone";
import { MAX_FILE_SIZE_BYTES } from "@utils/files";
import { cn } from "@src/ui/lib/utils";

type Props = {
  setMediaUrl: (url: string) => void;
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  isFileUploading: boolean;
  setIsFileUploading: (isUploading: boolean) => void;
};

const FileDropZone: React.FC<Props> = (props) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [onFileSelected, { loading }] = useFileUpload((e: MediaFragment) => {
    props.setMediaUrl(e.url);
    props.setIsFileUploading(false);
  });
  const onDrop = React.useCallback(
    <T extends File>(acceptedFiles: T[]) => {
      if (acceptedFiles.length === 1) {
        onFileSelected(acceptedFiles[0]);
      }
    },
    [onFileSelected],
  );
  const { getRootProps } = useDropzone({
    useFsAccessApi: true,
    multiple: false,
    noClick: true,
    noKeyboard: true,
    maxSize: MAX_FILE_SIZE_BYTES,
    onDrop,
  });
  const maybeOpenFilePicker = React.useCallback(() => {
    if (props.onClick) {
      props.onClick();
    } else if (fileInputRef?.current && !props.onClick) {
      fileInputRef.current.click();
    }
  }, [fileInputRef, props.onClick]);
  React.useEffect(() => {
    if (!props.isFileUploading && loading) {
      props.setIsFileUploading(true);
    }
  }, [loading, props.isFileUploading, props]);
  return (
    <div className="flex flex-1 self-stretch" {...getRootProps()}>
      <div
        className={cn(
          "flex flex-1 flex-col items-center justify-center self-stretch",
          props.className,
        )}
        onClick={maybeOpenFilePicker}
      >
        <input
          type="file"
          multiple={false}
          ref={fileInputRef}
          disabled={props.disabled}
          style={{ display: "none" }}
          onChange={(e) => {
            if (e.target.files && e.target.files.length === 1) {
              onFileSelected(e.target.files[0]);
            }
          }}
        />
        {props.children}
      </div>
    </div>
  );
};

export default FileDropZone;
