import { gradientStyles } from "@src/components/ui/CoverImage";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { BackgroundGradient } from "@src/types.generated";
import { css, StyleSheet } from "aphrodite";
import { FC } from "react";

type Props = {
  logoUrl?: string | null;
  orgId: number;
};
export const OrgLogo: FC<Props> = ({ logoUrl, orgId }) => {
  return logoUrl ? (
    <img src={logoUrl} alt="org-icon" className={css(styles.orgIcon)} />
  ) : (
    <div
      className={css(styles.noOrgIcon, gradientStyles[getGradient(orgId)])}
    />
  );
};

const styles = StyleSheet.create({
  noOrgIcon: {
    width: 28,
    height: 28,
    borderRadius: 28,
    backgroundColor: deprecatedTones.gray5,
  },
  orgIcon: {
    width: 28,
    height: 28,
    borderRadius: 28,
    overflow: "hidden",
  },
});
const getGradient = (id: number): BackgroundGradient => {
  const mod = id % 10;
  switch (mod) {
    case 0:
      return BackgroundGradient.Gradient_1;
    case 1:
      return BackgroundGradient.Gradient_2;
    case 2:
      return BackgroundGradient.Gradient_3;
    case 3:
      return BackgroundGradient.Gradient_4;
    case 4:
      return BackgroundGradient.Gradient_5;
    case 5:
      return BackgroundGradient.Gradient_6;
    case 6:
      return BackgroundGradient.Gradient_7;
    case 7:
      return BackgroundGradient.Gradient_8;
    case 8:
      return BackgroundGradient.Gradient_9;

    default:
      return BackgroundGradient.Gradient_10;
  }
};
