import React, { FC, useMemo, CSSProperties } from "react";
import Text from "@ui/text";
import { Tooltip, TooltipContent, TooltipTrigger } from "@ui/tooltip";
import { DeprecatedToneName } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { css, StyleDeclaration, StyleSheet } from "aphrodite";
import { AVATAR_COLOR_NAMES, avatarColors } from "../styles/avatarColors";
import { Route } from "nextjs-routes";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";

export type AvatarPerson = {
  name?: string | null | undefined;
  id: number | undefined;
};

type Props = {
  person: AvatarPerson;
  size: "24px" | "32px" | "36px" | "40px";
  color?: DeprecatedToneName;
  styleDeclaration?: StyleDeclaration;
  style?: CSSProperties;
  route?: Route;
};

const PersonAvatar: FC<Props> = ({
  person,
  size,
  color,
  styleDeclaration,
  route,
}) => {
  const { name, id = 0 } = person;
  const initial: number | undefined = useMemo(
    () => (name?.trim().toUpperCase() || " ").codePointAt(0),
    [name],
  );
  const parts: string[] = useMemo(() => (name ? name?.split(" ") : []), [name]);
  const lastLetter: number | undefined = useMemo(
    () =>
      parts.length > 1
        ? parts[parts.length - 1].toUpperCase().codePointAt(0)
        : undefined,
    [parts],
  );
  const avatarColorIndex = id % AVATAR_COLOR_NAMES.length;
  const toneNameFromId = AVATAR_COLOR_NAMES[avatarColorIndex];
  const avatarColorValues = color
    ? avatarColors[color]
    : avatarColors[toneNameFromId];
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <AutoLayout
          className={css(styles.container, styleDeclaration)}
          style={{
            width: size,
            height: size,
            cursor: route ? "pointer" : undefined,
            backgroundColor: avatarColorValues.backgroundColor,
          }}
          link={route}
        >
          <Text
            type={size === "24px" ? "P4" : size === "32px" ? "P3" : "P2"}
            fontWeight="SemiBold"
            color={avatarColorValues.color}
          >
            {initial && String.fromCodePoint(initial)}
            {lastLetter && String.fromCodePoint(lastLetter)}
          </Text>
        </AutoLayout>
      </TooltipTrigger>
      <TooltipContent
        side="bottom"
        style={{
          backgroundColor: avatarColorValues.backgroundColor,
          color: avatarColorValues.color,
        }}
      >
        {person.name}
      </TooltipContent>
    </Tooltip>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 40,
    boxShadow: "0 0 0 2px white",
  },
});

export default PersonAvatar;
