import React, { FC } from "react";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import useBrandColor from "@hooks/useBrandColor";
import { addDeprecatedOpacityToHexColor } from "@src/deprecatedDesignSystem/styles/deprecatedColors";

type Props = {
  centerContent: React.ReactNode;
  footerContent?: React.ReactNode;
  overlayContent?: React.ReactNode;
};

const PromptMediaFileAndLinkContainer: FC<Props> = (props) => {
  const brandColor = useBrandColor();

  return (
    <AutoLayout
      id={"PromptMediaFileAndLinkContainer"}
      flex={1}
      alignSelf={"stretch"}
      direction={"vertical"}
      padding={22}
      style={{
        position: "relative",
        backgroundColor: addDeprecatedOpacityToHexColor(brandColor, 0.2),
      }}
    >
      {props.footerContent && <AutoLayout flex={1} />}
      <AutoLayout
        flex={1}
        alignSelf={"stretch"}
        direction={"vertical"}
        alignmentVertical={"center"}
        alignmentHorizontal={"center"}
      >
        {props.centerContent}
      </AutoLayout>
      {props.footerContent && (
        <AutoLayout
          alignSelf={"stretch"}
          direction={"vertical"}
          flex={1}
          alignmentVertical={"bottom"}
        >
          {props.footerContent}
        </AutoLayout>
      )}
      {props.overlayContent}
    </AutoLayout>
  );
};

export default PromptMediaFileAndLinkContainer;
