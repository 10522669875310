import Tooltip, {
  TooltipPosition,
} from "@src/deprecatedDesignSystem/components/Tooltip";
import * as RadixTooltip from "@radix-ui/react-tooltip";
import { FC } from "react";

type Props = {
  children: React.ReactNode;
  hasPermission?: boolean;
  tooltipSide?: RadixTooltip.TooltipContentProps["side"];
  tooltipAlign?: RadixTooltip.TooltipContentProps["align"];
  caretPosition?: TooltipPosition;
  zIndex?: number;
  disabledOpacity?: number;
};

const NoPermissionsTooltipWrapper: FC<Props> = ({
  children,
  hasPermission = false,
  tooltipSide = "right",
  tooltipAlign = "center",
  caretPosition = "Left",
  zIndex = 1000,
  disabledOpacity = 0.6,
}) => {
  if (hasPermission) {
    return <>{children}</>;
  }
  return (
    <RadixTooltip.Root delayDuration={0}>
      <RadixTooltip.Trigger
        asChild
        style={{
          cursor: "not-allowed",
          opacity: disabledOpacity,
        }}
        onClickCapture={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        {children}
      </RadixTooltip.Trigger>
      <RadixTooltip.Content
        align={tooltipAlign}
        side={tooltipSide}
        style={{ zIndex }}
        sideOffset={4}
      >
        <Tooltip
          text="You do not have permission"
          caretPosition={caretPosition}
        />
      </RadixTooltip.Content>
    </RadixTooltip.Root>
  );
};

export default NoPermissionsTooltipWrapper;
