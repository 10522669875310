import { FC, useMemo } from "react";
import { StyleSheet } from "aphrodite";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Button from "@src/deprecatedDesignSystem/components/Button";

type Props = {
  url: string;
  buttonPosition?: "center" | "bottom-right";
};

const ShowViewButtonOverlay: FC<Props> = (props) => {
  const alignmentHorizontal = useMemo(() => {
    return props.buttonPosition === "center" ? "center" : "right";
  }, [props.buttonPosition]);
  const alignmentVertical = useMemo(() => {
    return props.buttonPosition === "center" ? "center" : "bottom";
  }, [props.buttonPosition]);

  return (
    <AutoLayout
      padding={16}
      alignmentHorizontal={alignmentHorizontal}
      alignmentVertical={alignmentVertical}
      styleDeclaration={styles.viewMediaOverlay}
    >
      <Button
        variant="Black"
        text="View"
        rightIcon="maximize-2"
        linkOpenInNewTab={true}
        onClickGoToExternalUrl={props.url}
      />
    </AutoLayout>
  );
};

const styles = StyleSheet.create({
  viewMediaOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
});

export default ShowViewButtonOverlay;
