import LinearGradient from "@components/ui/LinearGradient";
import { gradientsArray } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Text from "@ui/text";
import { emojiToNumber } from "@src/utils/strings";
import { StyleSheet, css } from "aphrodite";
import { FC, useMemo } from "react";

type Props = {
  emoji: string;
};

const HEIGHT = 180;

const EmojiPreview: FC<Props> = ({ emoji }) => {
  const linearGradient = useMemo(() => {
    return gradientsArray[emojiToNumber(emoji) % gradientsArray.length];
  }, [emoji]);

  return (
    <AutoLayout
      width={"100%"}
      height={HEIGHT}
      alignmentHorizontal="center"
      alignmentVertical="center"
      className={css(styles.emojiPreview)}
    >
      <div className={css(styles.blurred)}>
        <LinearGradient
          height={HEIGHT}
          width={"100%"}
          x1={HEIGHT}
          colors={linearGradient}
        />
      </div>
      <AutoLayout
        flex={1}
        width={"100%"}
        height={"100%"}
        alignSelf="stretch"
        alignmentHorizontal="center"
        alignmentVertical="center"
        className={css(styles.absoluteContainer)}
      >
        <Text type="H1" styleDeclaration={styles.emoji}>
          {emoji}
        </Text>
      </AutoLayout>
    </AutoLayout>
  );
};

const styles = StyleSheet.create({
  emojiPreview: {
    borderRadius: 32,
    overflow: "hidden",
    position: "relative",
  },
  blurred: {
    filter: "blur(10px)",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
  },
  absoluteContainer: {
    position: "relative",
    zIndex: 2,
  },
  emoji: {
    fontSize: 40,
  },
});

export default EmojiPreview;
